import React from "react"
import styled from "styled-components"
import BGIMG0 from "../../../images/homepagenew.png"
import BGIMG1 from "../../../images/homepagenew.png"

const LandingWallpaper = styled.div`
  height: 107vh;
  background-image: ${(props) =>
    props.backgroundImage ? props.backgroundImage : `url(${BGIMG0})`};
  background-position: right;
  background-repeat: no-repeat;
  /* background-size: cover; */
  @media (min-device-width: 768px) and (max-device-width: 1024px) {
    margin-top: 60px;
    height: 48vh;
    background-image: ${(props) =>
      props.backgroundImage ? props.backgroundImage : `url(${BGIMG0})`};
    background-position: right;
    background-repeat: no-repeat;
    /* background-size: cover; */
  }
  @media screen and (max-width: 992px) {
  }

  /* On screens that are 600px or less,*/
  @media screen and (max-width: 600px) {
    height: 48vh;
    margin-top: -18px;
    background-image: ${(props) =>
      props.mobileBackgroundImage
        ? props.mobileBackgroundImage
        : `url(${BGIMG1})`};
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
`
export default LandingWallpaper
