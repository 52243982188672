import React from "react"
import { PrivacyPolicyBox, Content4, Privacypolicy } from "../../components/UI"

import { LandingScreen } from "../Home/Styled"

import Signup from "../Home/signup"
import "./Privacypolicy.css"

function PrivacyPolicyPage() {
  return (
    <div>
      <LandingScreen
      // height="600px"
      // style={{
      //   backgroundImage: `url(${BGIMG})`,

      //   backgroundSize: "cover",
      // }}
      >
        {/* <div className="top-left_privacy">
          <h1> Privacy Policy</h1>
        </div> */}
      </LandingScreen>
      <div className="page_body1">
        <div className="career-heading">
          {/* <Content4>
                        <h3>PRIVACY POLICY</h3>
                    </Content4> */}
        </div>
        <Privacypolicy>
          <PrivacyPolicyBox>
            <div className="privacy-heading" style={{}}>
              <b>Privacy Policy</b>
            </div>
            <Content4>
              <br />
              <div style={{ textAlign: "justify" }}>
                <p>
                  Welcome, and thank you for your interest in Korero (“Korero”,
                  “we,” or “us”), our web site at https://www.tekorero.com (the
                  “Site”), and all related web sites (including our parent
                  company's (Innoverenit Private Services Limited) website
                  www.innoverenit.com), downloadable software, mobile
                  applications and other services provided by us and on which a
                  link to this Privacy Policy is displayed, and all other
                  communications with individuals through written or oral means,
                  such as email or phone (collectively, together with the Site,
                  our “Service”).
                  <br />
                  <br />
                  This Privacy Policy (“Policy”) describes the information that
                  we gather on or through the Service, how we use and disclose
                  such information, and the steps we take to protect such
                  information. By visiting the Site, or by purchasing or using
                  the Service, you accept the privacy practices described in
                  this Policy.
                  <br />
                  <br />
                  This Policy is incorporated into, and is subject to, Korero's
                  Terms of Service. Capitalized terms used but not defined in
                  this Policy have the meaning given to them in the Korero's
                  Terms of Service.
                  <br />
                  <br />{" "}
                </p>

                <div
                  style={{ textAlign: "justify", marginLeft: 5, fontSize: 20 }}
                >
                  <b>DEFINITIONS</b>
                </div>
                <br />
                <p>
                  <b>1.</b>“Client” means a customer of Korero.
                  <br />
                  <br />
                  “Client Data“ means personal data, reports, addresses, and
                  other files, folders or documents in electronic form that a
                  User of the Service stores within the Service.
                  <br />
                  <br />
                  “Personal Data” means any information relating to an
                  identified or identifiable natural person.
                  <br />
                  <br />
                  “Public Area” means the area of the Site that can be accessed
                  both by Users and Visitors, without needing to log in.
                  <br />
                  <br />
                  “Restricted Area” means the area of the Site that can be
                  accessed only by Users, and where access requires logging in.
                  <br />
                  <br />
                  “User” means an employee, agent, or representative of a
                  Client, who primarily uses the restricted areas of the Site
                  for the purpose of accessing the Service in such capacity.
                  <br />
                  <br />
                  “Visitor” means an individual other than a User, who uses the
                  public area, but has no access to the restricted areas of the
                  Site or Service.
                  <br />
                  <br />
                  2. THE INFORMATION WE COLLECT ON THE SERVICE:
                  <br />
                  <br />
                  We collect different types of information from or through the
                  Service. The legal bases for Korero’s processing of personal
                  data are primarily that the processing is necessary for
                  providing the Service in accordance with Korero’s Terms of
                  Service and that the processing is carried out in Korero’s
                  legitimate interests, which are further explained in the
                  section “How We Use the Information We Collect” of this
                  Policy. We may also process data upon your consent, asking for
                  it as appropriate.
                  <br />
                  <br />
                  2.1 User-provided Information. When you use the Service, as a
                  User or as a Visitor, you may provide, and we may collect
                  Personal Data. Examples of Personal Data include name, email
                  address, mailing address, mobile phone number, and credit card
                  or other billing information. Personal Data also includes
                  other information, such as geographic area or preferences,
                  when any such information is linked to information that
                  identifies a specific individual. You may provide us with
                  Personal Data in various ways on the Service. For example,
                  when you register for an Account, use the Service and or send
                  us customer service -related requests.
                  <br />
                  <br />
                  2.2 Information Collected by Clients. A Client or User may
                  store or upload into the Service Client Data. Innoverenit has
                  no direct relationship with the individuals whose Personal
                  Data it hosts as part of Client Data. Each Client is
                  responsible for providing notice to its customers and third
                  persons concerning the purpose for which Client collects their
                  Personal Data and how this Personal Data is processed in or
                  through the Service as part of Client Data.
                  <br />
                  <br />
                  2.3 “Automatically Collected" Information. When a User or
                  Visitor uses the Service, we may automatically record certain
                  information from the User’s or Visitor’s device by using
                  various types of technology, including cookies, “clear gifs"
                  or “web beacons.” This “automatically collected" information
                  may include IP address or other device address or ID, web
                  browser and/or device type, the pages or other content the
                  User or Visitor views or interacts with on the Service, and
                  the dates and times of the visit, access, or use of the
                  Service. We also may use these technologies to collect
                  information regarding a Visitor or User’s interaction with
                  email messages, such as whether the Visitor or User opens,
                  clicks on, or forwards a message. This information is gathered
                  from all Users and Visitors.
                  <br />
                  <br />
                  2.4 Integrated Services. You may be given the option to access
                  or register for the Service through the use of your user name
                  and passwords for certain services provided by third parties
                  (each, an “Integrated Service”), such as through the use of
                  your Google account, or otherwise have the option to authorize
                  an Integrated Service to provide Personal Data or other
                  information to us. By authorizing us to connect with an
                  Integrated Service, you authorize us to access and store your
                  name, email address(es), date of birth, gender, current city,
                  profile picture URL, and other information that the Integrated
                  Service makes available to us, and to use and disclose it in
                  accordance with this Policy. You should check your privacy
                  settings on each Integrated Service to understand what
                  information that Integrated Service makes available to us, and
                  make changes as appropriate. Please review each Integrated
                  Service’s terms of use and privacy policies carefully before
                  using their services and connecting to our Service.
                  <br />
                  <br />
                  2.5 Information from Other Sources. We may obtain information,
                  including Personal Data, from third parties and sources other
                  than the Service, such as our partners, advertisers, credit
                  rating agencies, and Integrated Services. If we combine or
                  associate information from other sources with Personal Data
                  that we collect through the Service, we will treat the
                  combined information as Personal Data in accordance with this
                  Policy.
                  <br />
                  <br />
                  3. HOW WE USE THE INFORMATION WE COLLECT
                  <br />
                  <br />
                  We use the information that we collect in a variety of ways in
                  providing the Service and operating our business, including
                  the following:
                  <br />
                  <br />
                  3.1 Operations
                  <br />
                  <br />
                  We use the information – other than Client Data - to operate,
                  maintain, enhance and provide all features of the Service, to
                  provide the services and information that you request, to
                  respond to comments and questions and to provide support to
                  users of the Service. We process Client Data solely in
                  accordance with the directions provided by the applicable
                  Client or User.
                  <br />
                  <br />
                  3.2 Improvements
                  <br />
                  <br />
                  We use the information to understand and analyze the usage
                  trends and preferences of our Visitors and Users, to improve
                  the Service, and to develop new products, services, feature,
                  and functionality. Should this purpose require Innoverenit to
                  process Client Data, then the data will only be used in
                  anonymized or aggregated form.
                  <br />
                  <br />
                  3.3 Communications
                  <br />
                  <br />
                  We may use a Visitor’s or User’s email address or other
                  information – other than Client Data – to contact that Visitor
                  or User (i) for administrative purposes such as customer
                  service, to address intellectual property infringement, right
                  of privacy violations or defamation issues related to the
                  Client Data or Personal Data posted on the Service or (ii)
                  with updates on promotions and events, relating to products
                  and services offered by us and by third parties we work with.
                  You have the ability to opt-in for receiving any promotional
                  communications as described below under “Your Choices.”
                  <br />
                  <br />
                  3.4 Cookies and Tracking Technologies
                  <br />
                  <br />
                  We use automatically collected information and other
                  information collected on the Service through cookies and
                  similar technologies to: (i) personalize our Service, such as
                  remembering a User’s or Visitor’s information so that the User
                  or Visitor will not have to re-enter it during a visit or on
                  subsequent visits; (ii) provide customized advertisements,
                  content, and information; (iii) monitor and analyze the
                  effectiveness of Service and third-party marketing activities;
                  (iv) monitor aggregate site usage metrics such as total number
                  of visitors and pages viewed; and (v) track your entries,
                  submissions, and status in any promotions or other activities
                  on the Service. You can obtain more information about cookies
                  by visiting http://www.allaboutcookies.org.
                  <br />
                  <br />
                  3.5 Analytics
                  <br />
                  <br />
                  We use Google Analytics to measure and evaluate access to and
                  traffic on the Public Area of the Site, and create user
                  navigation reports for our Site administrators. Google
                  operates independently from us and has its own privacy policy,
                  which we strongly suggest you review. Google may use the
                  information collected through Google Analytics to evaluate
                  Users' and Visitors’ activity on our Site. For more
                  information, see Google Analytics Privacy and Data Sharing.
                  <br />
                  <br />
                  We take measures to protect the technical information
                  collected by our use of Google Analytics. The data collected
                  will only be used on a need to know basis to resolve technical
                  issues, administer the Site and identify visitor preferences;
                  but in this case, the data will be in non-identifiable form.
                  We do not use any of this information to identify Visitors or
                  Users.
                  <br />
                  <br />
                  {/* 3.6 Additional Limits on Use of Your Google User Data<br /><br />

                                    Notwithstanding anything else in this Privacy Policy, if you provide the App access to your Google data, the App's use of that
                                    data will be subject to these additional restrictions:<br /><br />

                                    The App will only use access to read, write, modify or control Gmail message bodies (including attachments), metadata, headers,
                                    and settings to provide a web email client that allows users to compose, send, read,and process emails and will not transfer this
                                    Gmail data to others unless doing so is necessary to provide and improve these features, comply with applicable law, or as part
                                    of a merger, acquisition, or sale of assets.<br /><br />

                                    The App will not use this Gmail data for serving advertisements.<br /><br />

                                    The App will not allow humans to read this data unless we have your affirmative agreement for specific messages, doing so is
                                    necessary for security purposes such as investigating abuse, to comply with applicable law, or for the App's internal operations
                                     and even then only when the data have been aggregated and anonymized.<br /><br /> */}
                  4. TO WHOM WE DISCLOSE INFORMATION
                  <br />
                  <br />
                  Except as described in this Policy, we will not intentionally
                  disclose the Personal Data or Client Data that we collect or
                  store on the Service to third parties without the consent of
                  the applicable Visitor, User or Client. We may disclose
                  information to third parties if you consent to us doing so, as
                  well as in the following circumstances:
                  <br />
                  <br />
                  4.1 Unrestricted Information
                  <br />
                  <br />
                  Any information that you voluntarily choose to include in a
                  Public Area of the Service, such as a public profile page,
                  will be available to any Visitor or User who has access to
                  that content.
                  <br />
                  <br />
                  4.2 Other Users in Your Company Account
                  <br />
                  <br />
                  Certain information about your use of the Korero Services is
                  available to the administrator(s) of your Korero Account and,
                  depending on the settings chosen by the Users of the Account,
                  also to other Users for the purposes of providing the Korero
                  Services.
                  <br />
                  <br />
                  4.3 Service Providers
                  <br />
                  <br />
                  We work with third party service providers who provide
                  website, application development, hosting, maintenance, and
                  other services for us. These third parties may have access to,
                  or process Personal Data or Client Data as part of providing
                  those services for us. We limit the information provided to
                  these service providers to that which is reasonably necessary
                  for them to perform their functions, and our contracts with
                  them require them to maintain the confidentiality of such
                  information.
                  <br />
                  <br />
                  4.4 Non Personally Identifiable Information
                  <br />
                  <br />
                  We may make certain automatically-collected, aggregated, or
                  otherwise non-personally-identifiable information available to
                  third parties for various purposes, including (i) compliance
                  with various reporting obligations; (ii) for business or
                  marketing purposes; or (iii) to assist such parties in
                  understanding our Clients’, Users’ and Visitors’ interests,
                  habits, and usage patterns for certain programs, content,
                  services, and/or functionality available through the Service,
                  all of the foregoing being subject to additional limits on use
                  of your Google User data as stated in this Privacy Policy.
                  <br />
                  <br />
                  4.5 Law Enforcement, Legal Process and Compliance
                  <br />
                  <br />
                  We may disclose Personal Data or other information if required
                  to do so by law or in the good-faith belief that such action
                  is necessary to comply with applicable laws, in response to a
                  facially valid court order, judicial or other government
                  subpoena or warrant, or to otherwise cooperate with law
                  enforcement or other governmental agencies.
                  <br />
                  <br />
                  We also reserve the right to disclose Personal Data or other
                  information that we believe, in good faith, is appropriate or
                  necessary to (i) take precautions against liability, (ii)
                  protect ourselves or others from fraudulent, abusive, or
                  unlawful uses or activity, (iii) investigate and defend
                  ourselves against any third-party claims or allegations, (iv)
                  protect the security or integrity of the Service and any
                  facilities or equipment used to make the Service available, or
                  (v) protect our property or other legal rights, enforce our
                  contracts, or protect the rights, property, or safety of
                  others.
                  <br />
                  <br />
                  4.6 Change of Ownership
                  <br />
                  <br />
                  Information about Users and Visitors, including Personal Data,
                  may be disclosed and otherwise transferred to an acquirer,
                  successor or assignee as part of any merger, acquisition, debt
                  financing, sale of assets, or similar transaction, as well as
                  in the event of an insolvency, bankruptcy, or receivership in
                  which information is transferred to one or more third parties
                  as one of our business assets and only if the recipient of the
                  User or Visitor Data commits to a Privacy Policy that has
                  terms substantially consistent with this Privacy Policy.
                  <br />
                  <br />
                  Client Data may be physically or electronically transferred to
                  an acquirer, or successor or assignee as part of any merger,
                  acquisition, debt financing, sale of assets, or similar
                  transaction, as well as in the event of an insolvency,
                  bankruptcy, or receivership in which information is
                  transferred to one or more third parties as one of our
                  business assets, for the sole purpose of continuing the
                  operation of the Service, and only if the recipient of the
                  Client Data commits to a Privacy Policy that has terms
                  substantially consistent with this Privacy Policy.
                  <br />
                  <br />
                  5. YOUR CHOICES
                  <br />
                  <br />
                  5.1 Access, Correction, Deletion
                  <br />
                  <br />
                  We respect your privacy rights and provide you with reasonable
                  access to the Personal Data that you may have provided through
                  your use of the Services. If you wish to access or amend any
                  other Personal Data we hold about you, or to request that we
                  delete or transfer any information about you that we have
                  obtained from an Integrated Service, you may contact us as set
                  forth in the “Contact Us” section or email us on{" "}
                  <b>support@tekorero.com</b>. At your request, we will have any
                  reference to you deleted or blocked in our database.
                  <br />
                  <br />
                  You may update, correct, or delete your Account information
                  and preferences at any time by accessing your Account settings
                  page on the Service. Please note that while any changes you
                  make will be reflected in active user databases instantly or
                  within a reasonable period of time, we may retain all
                  information you submit for backups, archiving, prevention of
                  fraud and abuse, analytics, satisfaction of legal obligations,
                  or where we otherwise reasonably believe that we have a
                  legitimate reason to do so.
                  <br />
                  <br />
                  You may decline to share certain Personal Data with us, in
                  which case we may not be able to provide to you some of the
                  features and functionality of the Service.
                  <br />
                  <br />
                  At any time, you may object to the processing of your Personal
                  Data, on legitimate grounds, except if otherwise permitted by
                  applicable law. If you believe your right to privacy granted
                  by applicable data protection laws has been infringed upon,
                  please contact Korero’s Data Protection Officer at{" "}
                  <b>support@tekorero.com</b>. You also have a right to lodge a
                  complaint with data protection authorities.
                  <br />
                  <br />
                  This provision does not apply to Personal Data that is part of
                  Client Data. In this case, the management of the Client Data
                  is subject to the Client’s own Privacy Policy, and any request
                  for access, correction or deletion should be made to the
                  Client responsible for the uploading and storage of such data
                  into the Service.
                  <br />
                  <br />
                  5.2 Opting in for Commercial Communications
                  <br />
                  <br />
                  If you want to receive commercial emails from us, you may
                  subscribe at any time by ticking the box in the Profile
                  section. <br />
                  <br />
                  {/* 5.3 Opting out from Commercial Communications<br /><br /> */}
                  Please be aware that even if you do not opt-in, you will
                  continue to receive administrative messages from us regarding
                  the Service.
                  <br />
                  <br />
                  Innoverenit has no direct relationship with the Client’s
                  customers or third party whose Personal Data it may process on
                  behalf of a Client. An individual who seeks access, or who
                  seeks to correct, amend, delete inaccurate data or withdraw
                  consent for further contact should direct his or her query to
                  the Client or User they deal with directly. If the Client
                  requests Innoverenit to remove the data, we will respond to
                  its request within thirty (30) days. We will delete, amend or
                  block access to any Personal Data that we are storing only if
                  we receive a written request to do so from the Client who is
                  responsible for such Personal Data, unless we have a legal
                  right to retain such Personal Data. We reserve the right to
                  retain a copy of such data for archiving purposes, or to
                  defend our rights in litigation. Any such request regarding
                  Client Data should be addressed as indicated in the “Contact
                  Us” section, and include sufficient information for
                  Innoverenit to identify the Client or its customer or third
                  party and the information to delete or amend.
                  <br />
                  <br />
                  6. THIRD-PARTY SERVICES
                  <br />
                  <br />
                  The Service may contain features or links to web sites and
                  services provided by third parties. Any information you
                  provide on third-party sites or services is provided directly
                  to the operators of such services and is subject to those
                  operators’ policies, if any, governing privacy and security,
                  even if accessed through the Service. We are not responsible
                  for the content or privacy and security practices and policies
                  of third-party sites or services to which links or access are
                  provided through the Service. We encourage you to learn about
                  third parties’ privacy and security policies before providing
                  them with information.
                  <br />
                  <br />
                  7. INTEREST BASED ADVERTISING
                  <br />
                  <br />
                  Interest based advertising is the collection of data from
                  different sources and across different platforms in order to
                  predict an individual’s preferences or interest and to deliver
                  to that individual, or his/her computer, smart phone or
                  tablet, advertising based on his/her assumed preference or
                  interest inferred from the collection of data pertaining to
                  that individual or others who may have a similar profile or
                  similar interests.
                  <br />
                  <br />
                  We work with a variety of third parties to attempt to
                  understand the profiles of the individuals who are most likely
                  to be interested in Korero's products or services so that we
                  can send them promotional emails, or serve our advertisements
                  to them on the websites and mobile apps of other entities.
                  <br />
                  <br />
                  These third parties include: (a) advertising networks, which
                  collect information about a person’s interests when that
                  person views or interacts with one of their advertisements;
                  (b) attribution partners, which measure the effectiveness of
                  certain advertisements; and (c) business partners, which
                  collect information when a person views or interacts with one
                  of their advertisements.
                  <br />
                  <br />
                  In collaboration with these third parties, we collect
                  information about our customers, prospects and other
                  individuals over time and across different platforms when they
                  use these platforms or interact with them. Individuals may
                  submit information directly on our Sites or on platforms run
                  by third parties, or by interacting with us, our
                  advertisements, or emails they receive from us or from third
                  parties. We may use special tools that are commonly used for
                  this purpose, such as cookies, beacons, pixels, tags, mobile
                  advertising IDs, flash cookies, and similar technologies. We
                  may have access to databases of information collected by our
                  business partners.
                  <br />
                  <br />
                  The information we or third party collect enables us to learn
                  what purchases the person made, what ads or content the person
                  sees, on which ads or links the person clicks, and other
                  actions that the person takes on our Sites, or in response to
                  our emails, or when visiting or using third parties’
                  platforms.
                  <br />
                  <br />
                  We, or the third parties with which we work, use the
                  information collected as described above to understand the
                  various activities and behaviors of our customers, Site
                  visitors and others. We, or these third parties, do this for
                  many reasons, including: to recognize new or past visitors to
                  our Sites; to present more personalized content; to provide
                  more useful and relevant ads - for example, if we know what
                  ads you are shown we can try not to show you the same ones
                  repeatedly; to identify visitors across devices, sales
                  channels, third party websites and Sites, or to display or
                  send personalized or targeted ads and other custom content
                  that is more focused on a person’s perceived interest in
                  products or services similar to those that we offer.
                  <br />
                  <br />
                  Our interest-based ads may be served to you in emails or on
                  third-party platforms. We may serve these ads about our
                  products or services or send commercial communications
                  directly ourselves or through these third parties.
                  <br />
                  <br />
                  8. MINORS AND CHILDREN’S PRIVACY
                  <br />
                  <br />
                  Protecting the privacy of young children is especially
                  important. Our Service is not directed to children under the
                  age of 18, if you are under 18 years of age, then please do
                  not use or access the Service at any time or in any manner. If
                  you are a parent or guardian and discover that your child
                  under 18 years of age has obtained an Account on the Service,
                  then you may alert us at <b>support@tekorero.com</b> and
                  request that we delete that child’s Personal Data from our
                  systems.
                  <br />
                  <br />
                  The Service is not intended to be used by minors, and is not
                  intended to be used to post content to share publicly or with
                  friends. To the extent that a minor has posted such content on
                  the Service, the minor has the right to have this content
                  deleted or removed using the deletion or removal options
                  detailed in this Privacy Policy. If you have any question
                  regarding this topic, please contact us as indicated in the
                  “Contact Us” section. Please be aware that, although we offer
                  this deletion capability, the removal of content may not
                  ensure complete or comprehensive removal of that content or
                  information.
                  <br />
                  <br />
                  9. DATA SECURITY
                  <br />
                  <br />
                  We follow generally accepted industry standards to protect the
                  information submitted to us, both during transmission and once
                  we receive it. We maintain appropriate administrative,
                  technical and physical safeguards to protect Personal Data
                  against accidental or unlawful destruction, accidental loss,
                  unauthorized alteration, unauthorized disclosure or access,
                  misuse, and any other unlawful form of processing of the
                  Personal Data in our possession. This includes, for example,
                  firewalls, password protection and other access and
                  authentication controls. We use SSL technology to encrypt data
                  during transmission through public internet, and we also
                  employ application-layer security features to further
                  anonymize Personal Data.
                  <br />
                  <br />
                  However, no method of transmission over the Internet, or
                  method of electronic storage, is 100% secure. We cannot ensure
                  or warrant the security of any information you transmit to us
                  or store on the Service, and you do so at your own risk. We
                  also cannot guarantee that such information may not be
                  accessed, disclosed, altered, or destroyed by breach of any of
                  our physical, technical, or managerial safeguards. If you
                  believe your Personal Data has been compromised, please
                  contact us as set forth in the “Contact Us” section.
                  <br />
                  <br />
                  If we learn of a security systems breach, we will inform you
                  and the authorities of the occurrence of the breach in
                  accordance with applicable law.
                  <br />
                  <br />
                  10. DATA RETENTION
                  <br />
                  <br />
                  We only retain the Personal Data collected from a User for as
                  long as the User’s account is active or otherwise for a
                  limited period of time as long as we need it to fulfill the
                  purposes for which we have initially collected it, unless
                  otherwise required by law. We will retain and use information
                  as necessary to comply with our legal obligations, resolve
                  disputes, and enforce our agreements as follows:
                  <br />
                  <br />
                  - the contents of closed accounts are deleted within 60 days
                  of the date of closure;
                  <br />
                  <br />
                  - billing information is retained for a period of 7 years as
                  of their provision to Innoverenit <br />
                  <br />
                  - information on legal transactions between Client and
                  Innoverenit is retained for a period of 10 years <br />
                  <br />
                  11. SETTINGS
                  <br />
                  <br />
                  Although we may allow you to adjust your privacy settings to
                  limit access to certain Personal Data, please be aware that no
                  security measures are perfect or impenetrable. We are not
                  responsible for circumvention of any privacy settings or
                  security measures on the Service. Additionally, we cannot
                  control the actions of other users with whom you may choose to
                  share your information. Further, even after information posted
                  on the Service is removed, caching and archiving services may
                  have saved that information, and other users or third parties
                  may have copied or stored the information available on the
                  Service. We cannot and do not guarantee that information you
                  post on or transmit to the Service will not be viewed by
                  unauthorized persons.
                  <br />
                  <br />
                  12. DATA CONTROLLER AND DATA PROCESSOR
                  <br />
                  <br />
                  Innoverenit does not own, control or direct the use of any of
                  the Client Data stored or processed by a Client or User via
                  the Service. Only the Client or Users are entitled to access,
                  retrieve and direct the use of such Client Data. Innoverenit
                  is largely unaware of what Client Data is actually being
                  stored or made available by a Client or User to the Service
                  and does not directly access such Client Data except as
                  authorized by the Client, or as necessary to provide Services
                  to the Client and its Users.
                  <br />
                  <br />
                  Because Innoverenit does not collect or determine the use of
                  any Personal Data contained in the Client Data and because it
                  does not determine the purposes for which such Personal Data
                  is collected, the means of collecting such Personal Data, or
                  the uses of such Personal Data, Innoverenit is not acting in
                  the capacity of data controller in terms of the European
                  Union’s General Data Protection Regulation (Regulation (EU)
                  2016/679, hereinafter “GDPR”) and does not have the associated
                  responsibilities under the GDPR. Innoverenit should be
                  considered only as a processor on behalf of its Clients and
                  Users as to any Client Data containing Personal Data that is
                  subject to the requirements of the GDPR. Except as provided in
                  this Privacy Policy, Innoverenit does not independently cause
                  Client Data containing Personal Data stored in connection with
                  the Services to be transferred or otherwise made available to
                  third parties, except to third party subcontractors who may
                  process such data on behalf of Innoverenit in connection with
                  Innoverenit’s provision of Services to Clients. Such actions
                  are performed or authorized only by the applicable Client or
                  User.
                  <br />
                  <br />
                  The Client or the User is the data controller under the
                  Regulation for any Client Data containing Personal Data,
                  meaning that such party controls the manner such Personal Data
                  is collected and used as well as the determination of the
                  purposes and means of the processing of such Personal Data.
                  <br />
                  <br />
                  Innoverenit is not responsible for the content of the Personal
                  Data contained in the Client Data or other information stored
                  on its servers (or its subcontractors’ servers) at the
                  discretion of the Client or User nor is Innoverenit
                  responsible for the manner in which the Client or User
                  collects, handles disclosure, distributes or otherwise
                  processes such information.
                  <br />
                  <br />
                  13. CHANGES AND UPDATES TO THIS POLICY
                  <br />
                  <br />
                  Please revisit this page periodically to stay aware of any
                  changes to this Policy, which we may update from time to time.
                  If we modify the Policy, we will make it available through the
                  Service, and indicate the date of the latest revision, and
                  will comply with applicable law. Your continued use of the
                  Service after the revised Policy has become effective
                  indicates that you have read, understood and agreed to the
                  current version of the Policy.
                  <br />
                  <br />
                  14. HOW TO CONTACT US
                  <br />
                  <br />
                  Please contact us with any questions or comments about this
                  Policy, your Personal Data, our use and disclosure practices,
                  or your consent choices by email at{" "}
                  <b>support@tekorero.com</b>. If you have any concerns or
                  complaints about this Policy or your Personal Data, you may
                  contact Korero’s Data Protection Officer by email at{" "}
                  <b>support@tekorero.com</b>.<br />
                </p>
              </div>
            </Content4>
          </PrivacyPolicyBox>
        </Privacypolicy>
      </div>
      <div className="policycontainer-3">
        <Signup />
      </div>
      {/* <div className="footer"></div> */}
    </div>
  )
}

export default PrivacyPolicyPage
