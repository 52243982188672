import React, { Component } from "react"
import { Content } from "../../components/UI"
import { Button } from "reactstrap"
import CARDIMG from "../../images/trykorero.jpg"
import "./HomePage.css"
import axios from "axios"
import { baseUrl } from "../../config/keys"
import Footer from "../../components/Footer"
class Signup extends Component {
  // constructor(props) {
  //   super(props)
  //   this.state = {
  //     email: "",
  //   }
  // }
  // changeHandler = (e) => {
  //   this.setState({ [e.target.name]: e.target.value })
  // }
  // submitHandler = (e) => {
  //   e.preventDefault()

  //   axios
  //     .post(`${baseUrl}/korerroMail?email=${this.state.email}`)
  //     .then((res) => {
  //       alert("Thanks for interest in Korero")
  //     })
  //     .catch((err) => console.log(err))
  // }
  render() {
    const { email } = this.props
    return (
      <div>
        <div className="foot">{/* <Footer /> */}</div>
        {/* <div className="signup">
          <div className="signupText">
            To receive interesting updates on Korero, join our mailing list
          </div>

          <div className="form-wrapper1">
            <div className="input_box">
              {" "}
              <input
                type="text"
                name="email"
                onChange={this.changeHandler}
                value={email}
                placeholder="Email address"
              ></input>
            </div>
            <div className="signup_button">
              <button
                onClick={this.submitHandler}
                className="emailupdate_button"
                // disabled
              >
                Subscribe
              </button>
            </div>
          </div>
        </div> */}
      </div>
    )
  }
}

export default Signup
