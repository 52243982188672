import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import PrivacyPolicy from '../modules/PrivacyPolicy/PrivacyPolicy'

const privacypolicy = () => (
    <Layout>
        <SEO title="Privacy Policy" />
        <PrivacyPolicy />
        <script src="https://platform.linkedin.com/in.js" type="text/javascript"> lang: en_US</script>
    </Layout>
)

export default privacypolicy