import styled from "styled-components"

export const Features = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  background-color: whitesmoke;
  margin: auto 50px;
  @media screen and (max-width: 992px) {
    flex-direction: column;
    margin-left: 10px;
    background-color: whitesmoke;
  }
  /* On screens that are 600px or less,*/
  @media screen and (max-width: 600px) {
    flex-direction: column;
    background-color: white;
  }
`
export const Features1 = styled.div`
  display: flex;
  background-color: white;
  text-align: center;
  flex-direction: row;
  justify-content: center;
  // height: 440px;

  @media screen and (max-width: 992px) {
    flex-direction: column;
    margin-left: 100px;
    margin-right: 100px;
    background-color: white;
    // margin-top: 430px;
  }
  /* On screens that are 600px or less,*/
  @media screen and (max-width: 600px) {
    margin-left: 1px;
    margin-right: 37px;
    background-color: white;
    // margin-top: 620px;
  }
`
export const Box1 = styled.div`
  background-color: white;
  /* padding: -1rem; */
  width: 350px;
  margin: 0.7rem;
  box-shadow: 5px 10px 18px grey;
  transition: 0.2s all linear;
  &:hover {
    transform: scale(1.02);
  }
  @media screen and (max-width: 992px) {
    width: 100%;
    margin-left: 50px;
    margin-right: 50px;
  }
  /* On screens that are 600px or less,*/
  @media screen and (max-width: 600px) {
    width: 100%;
    // margin:5px;
  }
`
export const Box = styled.div`
  background-color: white;
  /* padding: -1rem; */
  width: 347px;
  margin: 1.2rem;
  box-shadow: 5px 10px 18px grey;
  transition: 0.2s all linear;
  &:hover {
    transform: scale(1.02);
  }

  @media screen and (max-width: 992px) {
    width: 100%;
  }
  /* On screens that are 600px or less,*/
  @media screen and (max-width: 600px) {
    width: 100%;
    flex-direction: column;
  }
`

export const Pricing = styled.div`
  display: flex;
  background-color: whitesmoke;
  text-align: center;
  flex-direction: row;
  justify-content: center;
  margin: 40px 50px;
  // padding: 40px 40px;

  @media screen and (max-width: 992px) {
    flex-direction: column;
    padding-right: 100px;
  }
  /* On screens that are 600px or less,*/
  @media screen and (max-width: 600px) {
    flex-direction: column;
    background-color: whitesmoke;
    margin: 0px;
  }
`

export const Box2 = styled.div`
  background-color: white;
  display: flex;
  justify-content: center;
  padding: 1rem;
  width: 500px;
  margin: 1rem;
  /* margin-left:200px; */
  /* On screens that are 992px or less*/
  @media screen and (max-width: 992px) {
    width: 90%;
    height: 80%;
    margin: auto !important;
    /* margin-top: 300px !important; */
  }

  /* On screens that are 600px or less,*/
  @media screen and (max-width: 600px) {
    width: 90%;
    height: 80%;
    margin: auto !important;
    /* margin-top: 300px !important; */
  }
`
export const Box3 = styled.div`
  background-color: white;
  padding: 1rem;
  /* width: 1300px; */
  height: 300px;
  /* margin-left:65px;
  margin-right:65px; */
  margin: auto 65px;
  /* margin: 1rem; */
  @media screen and (max-width: 992px) {
  }
  /* On screens that are 600px or less,*/
  @media screen and (max-width: 600px) {
    margin-left: 3px;
    margin-right: 0px;
  }
`
export const Box4 = styled.div`
  background-color: white;
  padding: 1rem;
  width: 600px;
  height: 300px;
  margin: 1rem;
`
export const Box5 = styled.div`
  // background-color: whitesmoke;
  width: 600px;
  padding: 5rem;
  margin-right: 65px;
  /* margin: auto 65px; */
  /* margin-top:20px; */
  p {
    font-weight: 500;
    font-size: 0.938em;
    color: #4a4d57;
    font-family: "Roboto";
    margin-right: 50px;
  }
  @media screen and (max-width: 992px) {
  }
  /* On screens that are 600px or less,*/
  @media screen and (max-width: 600px) {
    margin-left: 0px;
    width: 100%;
    padding: 0.5rem;
    // margin-top: -100px;
  }
`
export const Box6 = styled.div`
  // background-color: whitesmoke;
  width: 700px;
  padding: 1rem;
  margin-left: 65px;
  /* margin: auto 60px; */
  /* margin-top:20px; */
  /* margin-left:-80px; */
  .p {
    margin-top: 80px;
  }

  @media screen and (max-width: 992px) {
    width: 100%;
    margin-left: 0;
  }
  /* On screens that are 600px or less,*/
  @media screen and (max-width: 600px) {
    width: 100%;
    margin-left: 0;
  }
`

export const Integrations = styled.div`
  display: flex;
  text-align: center;
  flex-direction: row;
  justify-content: center;
  min-height: 670px;
  // background-color: whiteSmoke;
 
    margin-right: 65px;
    margin-left: 65px;
}
  /* margin-top:5px; */
  @media screen and (max-width: 992px) {
  }
  
  /* On screens that are 600px or less,*/
  @media screen and (max-width: 600px) {
    flex-direction: column;
  }
 
    /* height: 100%; */
`
export const Integrations1 = styled.div`
  display: flex;

  text-align: center;
  flex-direction: row;
  justify-content: center;
  // background-color: whiteSmoke;

  margin-right: 65px;
  margin-left: 65px;

  @media screen and (max-width: 992px) {
  }
  /* On screens that are 600px or less,*/
  @media screen and (max-width: 600px) {
    flex-direction: column;
  }
`
export const Career = styled.div`
  /* display: flex;
  text-align: center;
  flex-direction: row;
  justify-content: center; */

  @media screen and (max-width: 992px) {
  }
  /* On screens that are 600px or less,*/
  @media screen and (max-width: 600px) {
    width: 90vw;
    margin: 0 auto !important;
  }
`

export const Contact = styled.div`
  display: flex;
  background-color: whitesmoke;
  text-align: center;
  flex-direction: row;
  justify-content: center;
  height: 530px;
  @media screen and (max-width: 992px) {
    flex-direction: column;
  }
  /* On screens that are 600px or less,*/
  @media screen and (max-width: 600px) {
    flex-direction: column;
  }
`
export const Heading = styled.h4`
  color: #4a4d57;
  font-family: "Roboto";
`

export const Card = styled.div`
  background-color: white;
`
export const Content = styled.div`
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding: 2rem;
  text-align: left;
  color: #4a4d57;
  font-family: "Roboto";
  margin: 0 !important;
  font-size: 1em !important;
  line-height: 21px !important;
  h4 {
    font-weight: 500;
    font-size: 1.25em;
    color: #4a4d57;
  }
  h2 {
    color: White;
    margin-bottom: 15px;
  }
  h1 {
    font-weight: 500;
    font-size: 2.25em;
    margin-top: 10px;
    margin-bottom: 34px;
    color: #333;
  }
  h3 {
    font-weight: bold;
    font-size: 10px;
    margin-top: 10px;
    margin-bottom: 34px;
    color: #333;
  }
  h6 {
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 1.375em;
    line-height: 30px;

    text-align: left;
    letter-spacing: -0.5px;

    color: #333333;
  }
  p {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 1em;
    line-height: 24px;
    text-align: left;
    color: #6b6a71;
  }
  @media screen and (max-width: 992px) {
    h3 {
      font-size: 1.125em;
      line-height: 20px;
    }
    h1 {
      font-size: 1.4rem;
    }
  }
  /* On screens that are 600px or less,*/
  @media screen and (max-width: 600px) {
    h2 {
      font-size: 1.25em;
    }
  }
`
export const Content1 = styled.div`
  p {
    font-weight: 500;
    font-size: 0.938em;
    color: #4a4d57;
    font-family: "Roboto";
  }
`
export const Content2 = styled.div`
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding: 1rem;
  text-align: center;
  color: #4a4d57;
  font-family: "Roboto";
  margin: 0 !important;
  /* font-size:1.000em!important; */
  line-height: 18px !important;
  h4 {
    font-weight: 500;
    font-size: 1.25em;
  }
  h2 {
    color: White;
  }
  h1 {
    font-weight: 500;
    font-size: 25px;
    margin-top: 10px;
    /* margin-bottom: 34px; */
    color: #333;
  }
  p {
    font-weight: 500;
    font-size: 0.938em;
    color: #4a4d57;
    font-family: "Roboto";
    text-align: "left";
  }
`

export const Privacypolicy = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;

  @media screen and (max-width: 992px) {
  }
  /* On screens that are 600px or less,*/
  @media screen and (max-width: 600px) {
    width: 90vw;
    margin: 0 auto !important;
  }
`
export const PrivacyPolicyBox = styled.div`
  background-color: white;
  padding: 1rem;
  /* width: 1300px; */
  height: 100%;
  margin: auto 65px;
  /* margin-left:65px;
  margin-right:65px; */
  /* margin: 1rem; */
  @media screen and (max-width: 992px) {
  }
  /* On screens that are 600px or less,*/
  @media screen and (max-width: 600px) {
    margin-left: 3px;
    margin-right: 0px;
  }
`
export const Content4 = styled.div`
  /* display: block; */
  /* margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px; */
  padding: 1rem;
  /* text-align: center; */
  color: #4a4d57;
  font-family: "Roboto";
  /* margin: 0 !important;
  font-size:1.000em !important;
  line-height: 21px !important; */
  h4 {
    font-weight: 500;
    font-size: 1.25em;
    margin-left: 20;
  }
  h3 {
    font-weight: 500;
    font-size: 1.25em;
  }
  h2 {
    color: White;
  }
  h1 {
    font-weight: 500;
    font-size: 2.25em;
    margin-top: 10px;
    margin-bottom: 34px;
    color: #333;
  }
  h4 {
  }
  @media screen and (max-width: 992px) {
    h3 {
      /* font-size: 1rem; */
      font-size: 1.125em;
    }
    h1 {
      font-size: 1.4rem;
    }
  }
`
/*
contact us
*/

export const ContactusBox = styled.div`
  background-color: white;
  padding-left: 2rem;
  padding-right: 2rem;
  width: 100%;
  padding: 30px;
  height: 100%;
  /* margin: auto 65px; */
  /* margin-left:65px;
  margin-right:65px; */
  /* margin: 1rem; */
  @media screen and (max-width: 992px) {
  }
  /* On screens that are 600px or less,*/
  @media screen and (max-width: 600px) {
    margin-left: 3px;
    margin-right: 0px;
  }
`
export const Contacts = styled.div`
  display: flex;
  text-align: left;
  flex-direction: row;
  justify-content: center;

  @media screen and (max-width: 992px) {
  }
  /* On screens that are 600px or less,*/
  @media screen and (max-width: 600px) {
    width: 90vw;
    margin: 0 auto !important;
  }
`
export const ContactBox = styled.div`
  background-color: #eee9e9;
  /* padding: 1rem; */
  /* width: 1300px; */
  height: "100px";

  /* margin: auto 65px; */
  /* margin-left:65px;
  margin-right:65px; */
  /* margin: 1rem; */
  @media screen and (max-width: 992px) {
  }
  /* On screens that are 600px or less,*/
  @media screen and (max-width: 600px) {
    margin-left: 3px;
    margin-right: 0px;
  }
`
